import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "home",
  "customerSection": {
    "title": "Urval av våra kunder",
    "customers": [{
      "logo": "/img/favicon.png",
      "path": "....",
      "size": 30
    }, {
      "logo": "/img/head.png",
      "path": "acne",
      "size": 32
    }, {
      "logo": "/img/arrow.png",
      "path": "somecustomer",
      "size": 30
    }]
  },
  "seo": {
    "title": "Zimply",
    "descr": "-"
  },
  "hero": {
    "heroBackground": "/img/homehero.webp",
    "brand": "/img/zimply-logo-color.webp",
    "heroText": "Automatisera med digitala assistenter. Få bort tidskrävande arbetsuppgifter",
    "heroButtonText": "Skapa din assistent",
    "textRotator": [{
      "text": "Human"
    }, {
      "text": "More Time"
    }, {
      "text": "Your Way"
    }, {
      "text": "Automation"
    }],
    "logo": "/img/zimply-text-white.webp",
    "heroButtonPath": "createassistant"
  },
  "videoSection": {
    "title": "Automatisera med digitala assistenter",
    "text": "Ta tillbaka din tid. Zimply’s digitala assistenter automatiserar det tråkiga jobbet! Våra digitala assistenter automatiserar era tidskrävande arbetsuppgifter - eller de som ni helt enkelt tröttnat på att utföra manuellt.",
    "buttonText": "Boka demo",
    "videoUrl": "https://player.vimeo.com/video/598866568?h=ba0b099060",
    "buttonPath": "demo"
  },
  "sellingPoints": {
    "title": "Kom igång",
    "arrow": "/img/arrow-right.webp",
    "points": [{
      "title": "Förenkla ditt arbete",
      "text": "Vill du fokusera din värdefulla tid på vettigare uppgifter? Vill ni öka kvaliteten? Zimply hjäper er att effektivisera ert arbete samtidigt som vi automatiserer processer ni inte vill göra manuellt."
    }, {
      "title": "Zimply gör vår magi",
      "text": "Zimply utbildar er Digitala Assistent som automatiserer den valda arbetsuppgiften. Samtidigt som vi förbättrar och optimerar processen"
    }, {
      "title": "AI-assistenten är live",
      "text": "Nu kan du njuta av att jobba med uppgifter som du tycker om och som är bättre nytta för företaget"
    }],
    "robotHead": "/img/head.webp"
  },
  "customerTitle": "Urval av våra kunder",
  "squares": {
    "text1": "Vi utbildar och hyr ut digitala assistenter som hjälper er att få bort monotona och repetitiva arbetsuppgifter",
    "text2": "Är du redo att byta jobb? Vi planerar långsiktigt och ser över dina personliga mål, tillsammans planerar vi för en ljus framtid.",
    "bg1": "/img/pink1.webp",
    "bg2": "/img/pink.png",
    "buttonText1": "Läs mer",
    "buttonText2": "Läs mer",
    "quote1": "Quote 1",
    "quote2": "Quote 2",
    "image1": "/img/office2.webp",
    "image2": "/img/office1.webp",
    "title1": "Om oss",
    "buttonPath1": "about",
    "title2": "Karriär",
    "buttonPath2": "career"
  },
  "endSection": {
    "title": "Vill du veta mer?",
    "buttonText": "Kontakta oss",
    "icon": "/img/symbol-rosa-glödlampa.webp",
    "buttonPath": "contact"
  },
  "title": "Hem svenska"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      